@import "settings";
@import "mixins";

.view-news-slider {
  .view-header {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    @media screen and (min-width: 400px) {
      flex-flow: row nowrap;
    }
  }

  .view-title {
    display: inline-block;
    font: 23px/26px $font-family--heading;
  }

  .explore-blog-button {
    float: right;
    margin: 1rem 0;
  }

  .view-content {
    margin: 0 -1rem;
    max-height: 350px;
    min-height: 1px;
    opacity: 0;
    padding: 0 2rem;
    transition: opacity 0.25s ease-out;
  }

  .views-row {
    min-width: 0;
    min-height: 0;
    padding: 1rem;
    transition: all ease-in-out 0.25s;
    width: 100%;

    &:hover {
      margin-top: -5px;

      .group-content {
        box-shadow: 0 15px 15px -15px $tekra-gray-dark;
      }
    }

    img {
      display: block;
      width: 100%;
    }

    .group-content {
      background-color: $tekra-gray;
      padding: 1.5rem;
      transition: all ease-in-out 0.25s;
    }

    .field-name-title {
      h3 {
        background: none;
        font: bold 15px/19px $font-family--heading;
        padding: 0;
        margin: 0 0 1rem;
      }

      a {
        color: $text-color;
      }
    }
  }

  .slick-arrow {
    color: $tekra-blue;
    display: block;
    font-size: 2.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;

    &.prev {
      left: 1rem;
    }

    &.next {
      right: 1rem;
    }

    &:hover {
      font-size: 3rem;

      &.prev {
        left: 0.5rem;
        padding-right: 0.5rem;
      }

      &.next {
        padding-left: 0.5rem;
        right: 0.5rem;
      }
    }
  }

  .slick-dots {
    @include slick-dots;
    bottom: -30px;
    left: 0;
  }
}

.view-display-id-block_latest_tekra_news {
  @include constrain-content;
}

.news-sidebar-block {
  margin: 0 auto;
  max-width: 320px;

  @media screen and (min-width: 800px) {
    max-width: 205px;
  }

  h2 {
    font: bold 15px/19px $font-family--body;
    text-align: center;
    text-transform: capitalize;
  }
}
